
import { defineComponent } from 'vue'
import { urlTobase64, shake } from '@/utils/util'
import { recharge, ocrReadRechargeAmountApi, checkRechargeAmountApi } from '@/api/home'
import { ElMessage } from 'element-plus'
import user from '@/utils/user'
import QRCode from 'qrcodejs2'
import clipboard from 'clipboard'

export default defineComponent({
    props: ['dataTitle', 'width', 'isCloseType', 'openBank', 'bankNo', 'bankName', 'useWay', 'lockArr'],
    data() {
        return {
            stepList: [
                {
                    text: '发起充值',
                    hasNext: true
                },
                {
                    text: '客服审核',
                    hasNext: true
                },
                {
                    text: '充值成功',
                    hasNext: false
                }
            ],
            isRechargeOk: false,
            typeIndex: 0,
            num: '',
            isRepeat: false,
            ocrNum: '',
            ocrImg: '',
            fileValue: '' as any,
            qrcode: '' as any,
            window: window as any
        }
    },
    methods: {
        copyText() {
            let text = `企业全称：${this.bankName};银行账号：${this.bankNo};开户行：${this.openBank}`
            clipboard.copy(text)
            ElMessage.success('复制成功')
        },
        onToBill() {
            this.close()
            this.$router.push('/longinsure/bill')
        },
        close() {
            this.$emit('close')
            this.$Bus.emit('close2', '关闭了吗')
        },
        determine() {
            this.$emit('determine', this.num, this.ocrNum, this.ocrImg, this.isRepeat ? 1 : 0)
            this.isRechargeOk = true
        },
        onSwitchTab(event: number) {
            if (event === 1 && this.lockArr) {
                const arr = this.lockArr.filter((item: string) => item === '微信充值')
                if (arr.length > 0) {
                    this.$message.error('当前不支持' + arr[0])
                    return
                }
            }
            this.typeIndex = event
            if (Number(this.typeIndex) === 1) {
                this.num = ''
                // this.getRecharge()
            }
            this.num = ''
        },
        generateQRCode(url: { codeUrl: string }) {
            this.window.document.getElementById('code').innerHTML = ''
            this.qrcode = new QRCode('code', {
                text: url.codeUrl, // 二维码所携带的数据
                width: 180, // 二维码宽度
                height: 180 // 二维码高度
            })
        },
        async getRecharge() {
            const result = await recharge({
                amount: { total: this.num },
                total: this.num,
                description: '账户充值',
                mchid: user.wechatMchId,
                useWay: this.useWay
            })
            await this.generateQRCode(result)
        },
        // 获取本地img图片
        async handleFileUpload(event: any) {
            console.log(await urlTobase64(event))
            const code: any = await urlTobase64(event)
            console.log(code.split(',')[0].split('/')[0].split(':')[1])
            const flag = code.split(',')[0].split('/')[0].split(':')[1]
            if (flag === 'image') {
                let resoult = await ocrReadRechargeAmountApi({ voucherUrl: code.split(',')[1] })
                console.log(resoult, 'gfsdugyhfsd')
                this.num = resoult.ocrAmount
                this.ocrNum = resoult.ocrAmount
                this.ocrImg = resoult.ocrUrl
                this.fileValue = await urlTobase64(event)
                checkRechargeAmountApi({ rechargeAmount: resoult.ocrAmount }).then((res) => {
                    if (res === true) {
                        this.isRepeat = res
                    } else {
                        this.isRepeat = res.data
                    }
                })
            } else {
                console.log('false')
                ElMessage.error('请选择图片上传')
            }
        },
        onNum(event: string) {
            console.log(111)
            if (this.num.length === 0) {
                this.$message.error('请输入内容')
                return
            }
            if (Number(this.num) < 0) {
                this.$message.error('输入金额不可小于0元')
                return
            }
            // this.num = this.num.replace(/0/g, '')
            if (this.typeIndex === 1) {
                shake((): any => {
                    this.num = this.num.replace(/[^\d]/g, '').replace(/\./g, '')
                    this.getRecharge()
                })
            }
            if (this.typeIndex === 0) {
                shake((): any => {
                    checkRechargeAmountApi({ rechargeAmount: this.num }).then((res) => {
                        console.log(res, 'u还不够hi科技苏打火机更大说法卡拉季个等级考拉很尬科技溜达鸡考拉')
                        if (res === true) {
                            this.isRepeat = res
                        } else {
                            this.isRepeat = res.data
                        }
                    })
                }, 1000)
            }
        }
    }
})
